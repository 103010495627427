import React, { useState } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Backend from "../utils/api.js";
import Validators from "../utils/validators.jsx";
import ProfileAppBar from "./Components/ProfileAppBar.jsx";

/*
 * This component provides a full view of the authenticated user's "profile."
 * Provides a way to edit user information and connect their account with Canvas Badges.
 * Additionally provides an entry point to the Admin Dashboard for admins.
 */
const Profile = ({ user }) => {
  const [userState, setUserState] = useState(user); // stores state of user as fields are edited
  const [showMessage, setShowMessage] = useState(false); // stores state of whether or not to show a message
  const [messageText, setMessageText] = useState(""); // stores message to be displayed
  const [changesSaved, setChangesSaved] = useState(false);

  // Displays an auto-disappearing message to the user
  function flashMessage(message) {
    setShowMessage(true);
    setMessageText(message);
    setTimeout(() => {
      setShowMessage(false);
      setMessageText("");
    }, 3000);
  }

  return (
    <Flex display="column">
      <ProfileAppBar user={user} signoutLocation="/" />
      <Box display="flex" mx="7vw" mt={10} p={6} justifyContent="space-between">
        <Flex display="column" maxW="40vw">
          <Heading as="h1" size="xl" mb={8}>
            Hey {userState.firstName} 👋
          </Heading>
          <Text>
            Welcome to Pinpoint. Here, you can update your profile and connect
            your Canvas Badges account for use with makerspaces that participate
            in tool management.
          </Text>
          <Text
            as="h2"
            fontSize="xl"
            fontWeight={"bold"}
            mb={4}
            marginTop="3vh"
          >
            Edit Profile
          </Text>
          <form
            onSubmit={(e) => {
              if (Validators.validateUser(userState)) {
                setChangesSaved(true);
                setTimeout(() => {
                  setChangesSaved(false);
                }, 3000);
                //flashMessage("Profile changes saved!"); // show confirmation message
                Backend.editUser(userState);
              } else {
                e.preventDefault();
                console.log("Invalid - Please enter valid user info!"); // TODO: show error message
              }
            }}
          >
            <Flex alignItems="end" justifyContent="space-between">
              <FormControl id="firstName" marginRight="1vw">
                <FormLabel>First Name</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter first name"
                  value={userState.firstName}
                  onChange={(e) => {
                    Validators.constrainNameInput(e.target.value) &&
                      setUserState({ ...userState, firstName: e.target.value });
                  }}
                  isInvalid={!Validators.validateName(userState.firstName)}
                />
              </FormControl>
              <FormControl id="lastName" mt={4}>
                <FormLabel>Last Name</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter last name"
                  value={userState.lastName}
                  onChange={(e) => {
                    Validators.constrainNameInput(e.target.value) &&
                      setUserState({ ...userState, lastName: e.target.value });
                  }}
                  isInvalid={!Validators.validateName(userState.lastName)}
                />
              </FormControl>
            </Flex>
            <FormControl id="email" mt={4}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter email"
                value={userState.email}
                onChange={(e) => {
                  setUserState({ ...userState, email: e.target.value });
                }}
                isInvalid={!Validators.validateEmail(userState.email)}
              />
            </FormControl>
            <FormControl id="uid" mt={4}>
              <FormLabel>UID</FormLabel>
              <Input
                type="text"
                placeholder="Enter UID"
                value={userState.UID}
                disabled={true}
              />
            </FormControl>
            <Flex alignItems="end" justifyContent="space-between">
              <FormControl id="directoryId" marginRight="1vw" mt={4}>
                <FormLabel>Directory ID</FormLabel>
                <Input
                  type="text"
                  placeholder="Enter directory ID"
                  value={userState.directoryID}
                  maxLength={9}
                  onChange={(e) => {
                    setUserState({ ...userState, directoryID: e.target.value });
                  }}
                  isInvalid={
                    !Validators.validateDirectoryID(userState.directoryID)
                  }
                />
              </FormControl>
              <FormControl id="pin" mt={4}>
                <FormLabel>PIN</FormLabel>
                <Input
                  type="password"
                  placeholder="Enter PIN"
                  value={userState.pin}
                  onChange={(e) => {
                    Validators.contrainPinLength(e.target.value) &&
                      setUserState({ ...userState, pin: e.target.value });
                  }}
                  isInvalid={!Validators.validatePin(userState.pin)}
                />
              </FormControl>
            </Flex>
            <Button type="submit" variant="solid">
              Save Changes
            </Button>
            {changesSaved ? 
              <Alert status='success' variant='left-accent' my="2vh">
                <AlertIcon />
                <AlertTitle>Success</AlertTitle>
                <AlertDescription>Profile Changes Saved!</AlertDescription>
              </Alert> 
            : <></>}
            {showMessage && (
              <Text
                alignText="center"
                alignSelf="center"
                color="green.500"
                mt={4}
              >
                {messageText}
              </Text>
            )}
          </form>
        </Flex>
        <Box mt={10} display="column">
          <Text as="h2" fontSize="xl" fontWeight={"bold"} mb={4}>
            Canvas Badges
          </Text>
          <Text>
            Manage the connection to your Canvas Badges account for use with tool management.
          </Text>
          <Button
            maxW="15vw"
            variant="solid"
            onClick={() => {
              console.log(process.env.REACT_APP_BADGES_URI); // REMOVE IN PRODUCTION
              window.location.replace(process.env.REACT_APP_BADGES_URI); // open Oauth2 login page for canvas badges
            }}
          >
            Connect to Canvas Badges
          </Button>
        </Box>
      </Box>
    </Flex>
  );
};

Profile.propTypes = {
  user: PropTypes.shape({
    UID: PropTypes.number.isRequired,
  }).isRequired,
};

export default withRouter(Profile);
