/* 
 A function used to welcome the user to the lab using the browser's built-in speech synthesis.
*/
export default async function welcomeUser({
  synth,
  firstName,
  lastName,
  labName,
}) {
  const voices = synth.getVoices();
  const voice = voices.find(
    (voice) => voice.name === "Google UK English Female"
  );

  const utterance = new SpeechSynthesisUtterance(`${lastName}.`);
  utterance.voice = voice;
  utterance.onend = () => {
    const utterance2 = new SpeechSynthesisUtterance(
      `${firstName} ${lastName}.`
    );
    utterance2.voice = voice;
    utterance2.onend = () => {
      const utterance3 = new SpeechSynthesisUtterance(`Welcome to ${labName}.`);
      utterance3.voice = voice;
      utterance3.onend = () => {
        const utterance4 = new SpeechSynthesisUtterance(
          "Please select the tools you would like to use today."
        );
        utterance4.voice = voice;
        synth.speak(utterance4);
      };
      synth.speak(utterance3);
    };
    synth.speak(utterance2);
  };
  synth.speak(utterance);
}
