import { Box, Stat, StatLabel, StatNumber, Text, Textarea} from "@chakra-ui/react";

import { CheckCircleIcon } from "@chakra-ui/icons";

/*
  A selectable card component used to display a tool's information.
*/
function ToolCard({ key, type, name, isSelected, onClick, isDisabled }) {
  let backgroundColor = undefined
  if (isDisabled) {
    backgroundColor = "#575757"
  } else if (isSelected) {
    backgroundColor = "#F4FDF3"
  }

  return (
    <Stat
      key={key}
      onClick={onClick}
      _hover={{
        cursor: "pointer",
        transform: "scale(1.05)",
        transition: "transform .2s",
      }}
      width="15vw"
      px={{ base: 4, md: 8 }}
      py={"5"}
      shadow={"md"}
      border={"1px solid"}
      borderColor={isSelected ? "green" : "black"}
      rounded={"lg"}
      textAlign={"center"}
      backgroundColor={backgroundColor}
    >
      {isSelected && (
        <Box position="absolute" top="1" left="1">
          <CheckCircleIcon color="green.500" boxSize={6} />
        </Box>
      )}
      <StatLabel fontWeight={"medium"} isTruncated>
      </StatLabel>
      <Textarea fontSize={"xl"} fontWeight={"medium"} style={{overflow: "hidden", resize: "none"}} 
        isReadOnly="true" variant="unstyled" textAlign={"center"}>
        {name}
      </Textarea>
    </Stat>
  );
}

export default ToolCard;
