import React from "react";
import "../utils/default.css";
import Login from "../Login/Login.jsx";
import Admin from "../Admin/Admin.jsx";
import RegisterForm from "../RegisterForm/RegisterForm.jsx";
import TopUsers from "../Admin/TopUsers.jsx";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import LoginCAS from "../Login/LoginCAS";
import Pin from "../Login/Pin";
import { ChakraProvider } from "@chakra-ui/react";
import { chakraTheme, muiTheme } from "./theme.jsx";
import ToolSelection from "../User/ToolSelection.jsx";
import Profile from "../User/Profile.jsx";
import KioskLogin from "../Login/KioskLogin";
import InvalidScreen from "../Components/Invalid";
import Backend from "../utils/api";
import ThankYouPage from "../Components/ThankYou";
// import BadgrCallback from "../User/BadgrCallback";

class App extends React.Component {
  state = {
    user: {},
    lab: {},
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (
      window.location.pathname.startsWith("/kiosk") ||
      window.location.pathname.startsWith("/machine_selection")
    ) {
      console.log("determining lab...");
      this.determineLab();
    }
    window.onpopstate = this.backButton;
  }

  determineLab = async () => {
    var lab;
    try {
      // first try getting lab from URL
      const urlParams = new URLSearchParams(window.location.search);
      const labId = urlParams.get("lab");
      if (labId !== null) {
        lab = await Backend.getLabById({ id: labId, UID: "817500000" });
      } else {
        lab = JSON.parse(localStorage.getItem("lab"));
        // update URL to show lab parameter
        if (lab) {
          window.history.replaceState(null, null, `?lab=${lab.id}`);
        }
      }
    } catch (err) {
      lab = {
        id: "Error",
        name: "Error Loading Lab...",
      };
    }
    console.log("Setting lab to: ");
    console.table(lab);
    this.setLab(lab);
    console.log("done setting lab.");
  };

  login = (e) => {
    this.setState({ user: e });
  };

  logout = () => {
    if (this.state.user) {
      this.setState({
        user: {},
      });
    }
    window.location = "/";
  };

  setLab = (lab) => {
    localStorage.setItem("lab", JSON.stringify(lab));
    this.setState({ lab: lab });
  };

  render() {
    const user = this.state.user;
    const lab = this.state.lab;

    return (
      <ChakraProvider theme={chakraTheme}>
        <ThemeProvider theme={muiTheme}>
          <Router>
            <Switch>
              <Route exact path="/kiosk">
                <KioskLogin
                  onSubmit={(e) => {
                    this.setState({ user: e });
                  }}
                  currLab={lab}
                  setCurrLab={this.setLab}
                  routeFunc={(user_res, lab_permission_res) => {
                    if (user_res.status === 200) {
                      return "/machine_selection";
                    } else {
                      return "/register";
                    }
                  }}
                />
              </Route>
              <Route exact path="/">
                <Login
                  onSubmit={(e) => this.setState({ user: e })}
                  routeFunc={(user_res, lab_permission_res) => {
                    if (user_res.status === 200) {
                      if (
                        user_res.userType ||
                        lab_permission_res.items.some(
                          (ele) => ele.lab === lab.id
                        )
                      ) {
                        return "/admin";
                      } else {
                        return "/profile";
                      }
                    } else {
                      return "/invalid";
                    }
                  }}
                />
              </Route>

              <Route exact path="/caslogin">
                <LoginCAS></LoginCAS>
              </Route>

              <Route
                path="/machine_selection"
                render={(props) =>
                  user.UID === undefined ? (
                    <Redirect to="/kiosk" />
                  ) : (
                    <ToolSelection user={user} lab={lab} />
                  )
                }
              />
              <Route
                path="/invalid"
                render={(props) => <InvalidScreen></InvalidScreen>}
              />
              {/* <Route
                path="/oauth/callback"
                render={(props) => <BadgrCallback />}
              /> */}
              <Route
                path="/profile"
                render={(props) =>
                  user.UID === undefined ? (
                    <Redirect to="/" />
                  ) : (
                    <Profile user={user} />
                  )
                }
              />
              <Route
                path="/admin"
                render={(props) =>
                  user.UID === undefined ? (
                    <Redirect to="/" />
                  ) : (
                    <Admin user={user} />
                  )
                }
              />
              <Route path="/topusers" render={(props) => <TopUsers />} />
              <Route path="/register">
                <RegisterForm
                  UID={user.UID}
                  currLab={lab}
                  routeFunc={(account_creation_res) => {
                    if (
                      account_creation_res.status === 200 ||
                      account_creation_res.status === 201
                    ) {
                      return "/machine_selection";
                    } else {
                      return "/kiosk";
                    }
                  }}
                  onSubmit={(e) => {
                    this.setState({ user: e });
                  }}
                />
              </Route>
              <Route path="/pin">
                <Pin
                  UID={user.UID}
                  lab={lab}
                  routeFunc={(lab_permission_res) => {
                    return "/profile";
                  }}
                />
              </Route>
              <Route path="/thank_you"
                render={(props) => <ThankYouPage></ThankYouPage>}
              />
            </Switch>
          </Router>
        </ThemeProvider>
      </ChakraProvider>
    );
  }
}

export default App;
