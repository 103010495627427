import React from "react";
import { useEffect, useState } from "react";
import Backend from "../utils/api.js";
import "../utils/default.css";
import PropTypes from "prop-types";
import Validators from "../utils/validators.jsx";
import { useHistory } from "react-router-dom";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

/*
 * This component is used to register a new user.
 */
export default function RegisterForm({ UID, routeFunc, onSubmit, currLab }) {
  const [isLoading, setIsLoading] = useState(false);
  const [userState, setUserState] = useState({});
  const history = useHistory();

  useEffect(() => {
    Backend.getUserInfoFromUID(UID).then((userInfoResult) => {
      setUserState(userInfoResult); // autofill form with user info pulled from LDAP
    });
  }, [UID]);

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <form
        onSubmit={(x) => {
          setIsLoading(true);
          x.preventDefault();
          Backend.newUser({
            ...userState,
            swipeNum: UID === "undefined" ? "0" : UID,
          }) //If swipeNum is undefined, will default to 0. Will take in unique swipeNum otherwise
            .then((res) => {
              onSubmit(userState);
              Backend.userSwipeIn({
                userUID: UID,
                userSwipeNum: UID,
                lab: currLab.id,
              });
              history.push(routeFunc(res));
            });
        }}
      >
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"} textAlign={"center"}>
              Create an Account
            </Heading>
            <Text fontSize={"lg"} color={"gray.600"}>
              with Pinpoint
            </Text>
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <Stack spacing={4}>
              <HStack>
                <Box>
                  <FormControl id="firstName" isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      type="text"
                      value={userState.firstName}
                      onChange={(e) => {
                        Validators.constrainNameInput(e.target.value) &&
                          setUserState({
                            ...userState,
                            firstName: e.target.value,
                          });
                      }}
                      isInvalid={!Validators.validateName(userState.firstName)}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl id="lastName" isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      type="text"
                      value={userState.lastName}
                      onChange={(e) => {
                        Validators.constrainNameInput(e.target.value) &&
                          setUserState({
                            ...userState,
                            lastName: e.target.value,
                          });
                      }}
                      isInvalid={!Validators.validateName(userState.lastName)}
                    />
                  </FormControl>
                </Box>
              </HStack>
              <FormControl id="email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  value={userState.email}
                  onChange={(e) => {
                    setUserState({
                      ...userState,
                      email: e.target.value,
                    });
                  }}
                  isInvalid={!Validators.validateEmail(userState.email)}
                />
              </FormControl>
              <FormControl id="directoryID" isRequired>
                <FormLabel>Directory ID</FormLabel>
                <Input
                  type="text"
                  value={userState.directoryID}
                  onChange={(e) => {
                    setUserState({
                      ...userState,
                      directoryID: e.target.value,
                    });
                  }}
                  isInvalid={
                    !Validators.validateDirectoryID(userState.directoryID)
                  }
                />
              </FormControl>
              <FormControl id="UID" isRequired>
                <FormLabel>UID</FormLabel>
                <Input type="text" value={userState.UID} disabled={true} />
              </FormControl>
              <FormControl id="pin" isRequired>
                <FormLabel>4-Digit Pin</FormLabel>
                <Input
                  type={"password"}
                  value={userState.pin}
                  onChange={(e) => {
                    Validators.constrainPinInput(e.target.value) &&
                      setUserState({
                        ...userState,
                        pin: e.target.value,
                      });
                  }}
                  isInvalid={!Validators.validatePin(userState.pin)}
                />
              </FormControl>
              <Stack spacing={10} pt={2}>
                <Button
                  isLoading={isLoading}
                  loadingText={"Creating Account..."}
                  fontFamily={"heading"}
                  type="submit"
                >
                  Create Account
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Flex>
  );
}

RegisterForm.propTypes = {
  UID: PropTypes.number,
};
