import React, { useState, useEffect } from "react";
import Backend from "../utils/api.js";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import pinpointLogo from "../assets/pinpoint_no_bg.svg";
import CustomPopup from "../Components/CustomPopup.jsx";
import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Image,
  Box,
  FormLabel,
} from "@chakra-ui/react";
import Validators from "../utils/validators.jsx";

/* 
  Login page for users to enter their UID and be routed to Pin -> Profile.
  This page is used for editing account information and connecting Canvas Badges.
  Makerspaces should use the KioskLogin page.
  Note: Users cannot create an account from this page. Users must visit a Makerspace to create an account.
*/
function Login({ onSubmit, routeFunc, history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const login = (e) => {
    e.preventDefault();
    setIsLoading(true);

    Backend.getUser(input).then((user_res) => {
      if (user_res.status === 200) {
        //User has a pin and will be routed to pin page to enter it
        onSubmit(user_res);
        typeof routeFunc === "function" && history.push("/pin");
      } else {
        // show popup telling user to create an account in a Makerspace
        setIsLoading(false);
        onOpen();
      }
    });
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={20} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Image
          alignSelf="center"
          width="4em"
          src={pinpointLogo}
          alt="pinpoint logo"
          margin="2vh"
          position={"absolute"}
        ></Image>
        <Stack align={"center"}>
          <Heading fontSize={"5xl"} fontWeight="bold">
            Pinpoint
          </Heading>
          <Text fontSize={"md"} color={"gray.600"}>
            Manage your profile & connect your Canvas Badges account.
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <form onSubmit={login}>
            <Stack spacing={4}>
              <FormControl isRequired id="UID">
                <FormLabel>University ID</FormLabel>
                <Input
                  type="number"
                  textAlign={"center"}
                  maxLength={9}
                  autoFocus={true}
                  placeholder="e.g. 117123546"
                  value={input}
                  onChange={(e) => {
                    setInput(e.target.value);
                  }}
                  isInvalid={input.length > 0 && !Validators.validateUID(input)}
                />
              </FormControl>
              <Stack spacing={4}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                ></Stack>
                <Button
                  loadingText="Checking In..."
                  isLoading={isLoading}
                  type="submit"
                >
                  Log In
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
      {isOpen && (
        <CustomPopup
          isOpen={isOpen}
          onClose={onClose}
          header={"Account Does Not Exist"}
          message={
            "The entered UID does not correspond to an account in Pinpoint. Please visit a Makerspace kiosk to create an account."
          }
        ></CustomPopup>
      )}
    </Flex>
  );
}

Login.propTypes = {
  onSubmit: PropTypes.func,
};

export default withRouter(Login);
