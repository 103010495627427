import React, { useState } from "react";
import Backend from "../utils/api.js";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Validators from "../utils/validators.jsx";
import {
  Button,
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

/* 
  Intermediate page to authenticate users into the system.
*/
function Pin({ routeFunc, history, UID, lab }) {
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function updatePIN(input) {
    if (input.length <= 4) {
      setInput(input);
    }
  }

  // Will swipe out last user swipe-in (if applicable) and re-swipein user
  const updateUserSwipes = (userUID, userSwipeNum, lab) => {
    return Promise.all([
      Backend.userSwipeOut(userUID),
      Backend.userSwipeIn({
        userUID: userUID,
        userSwipeNum: userSwipeNum,
        lab: lab,
      }),
    ]);
  };

  const submitPin = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const re_pinnum = /^\d{4}$/;

    // User enters a 4 digit pin
    if (input.match(re_pinnum)) {
      Promise.all([
        Backend.getUserPin({ pin: input, UID: UID }),
        Backend.getLabPermissionsByUser(UID),
      ]).then((responses) => {
        var lab_permission_res = responses[1];
        // if PIN number is correct, route them to appropriate page based on lab permissions.
        // Also, swipe the user in
        if (responses[0].access === true) {
          updateUserSwipes(UID, 0, lab.id);
          typeof routeFunc === "function" &&
            history.push(routeFunc(lab_permission_res));
        } else {
          // Pin is incorrect, user will have to reenter PIN
          alert("Invalid PIN number! Try again");
        }
        setIsLoading(false);
      });
      //If user enters pin less than or greater than 4 digits, alert them
    } else {
      alert("PIN must be 4 digits");
      setIsLoading(false);
    }
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <form onSubmit={submitPin}>
        <Stack
          spacing={4}
          w={"full"}
          maxW={"md"}
          bg={useColorModeValue("white", "gray.700")}
          rounded={"xl"}
          boxShadow={"lg"}
          p={6}
          my={12}
        >
          <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
            PIN Authentication
          </Heading>
          <Text
            fontSize={{ base: "sm", sm: "md" }}
            color={useColorModeValue("gray.800", "gray.400")}
          >
            Please enter your four-digit PIN number to continue.
          </Text>
          <FormControl id="PIN">
            <Input
              type="password"
              autoFocus={true}
              _placeholder={{ color: "gray.500" }}
              value={input}
              placeholder="Example: 1234"
              textAlign={"center"}
              onChange={(e) => {
                const numMatch = e.target.value.match(/\d*/);
                numMatch && updatePIN(numMatch[0]);
              }}
              isInvalid={!Validators.validatePin(input)}
            />
          </FormControl>
          <Stack spacing={6}>
            <Button
              isLoading={isLoading}
              loadingText="Checking Credentials..."
              type="submit"
            >
              Continue
            </Button>
          </Stack>
        </Stack>
      </form>
    </Flex>
  );
}
Pin.propTypes = {
  onSubmit: PropTypes.func,
};

export default withRouter(Pin);
