import React, { useState, useEffect, useRef } from "react";
import Backend from "../utils/api.js";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import swipeAnimation from "../assets/swipeAnimation.json";
import pinpointLogo from "../assets/pinpoint_no_bg.svg";
import Lottie from "lottie-react";
import {
  FormControl,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
  Box,
  Image,
  Spinner,
  Center,
} from "@chakra-ui/react";
import Validators from "../utils/validators.jsx";
import SwipeOrReservePopup from "../Components/SwipeOrReservePopup.jsx";

/* 
  Login page for users to swipe their university ID card and be routed to the tool reservation page.
  This page is used as the way for labs to authenticate users in-person via swipe-ins.
*/
function KioskLogin({ onSubmit, routeFunc, history, currLab, setCurrLab }) {
  const SWIPE_IN = 1;
  const SWIPE_OUT = 2;
  const currLabName = (currLab && currLab.name) || "Loading";
  const [isLoading, setIsLoading] = useState(false);

  const [labs, setLabs] = useState([]);
  const [swipeMode, setSwipeMode] = useState(SWIPE_IN);
  const [input, setInput] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState("");
  const [showChoicePopup, setShowChoicePopup] = useState(false);
  const [UID, setUID] = useState("");

  const inputRef = useRef(null);

  // Checks if user is swiped in or not and routes them to the appropriate page
  async function processSwipe(e) {
    e.preventDefault();
    setIsLoading(true);
    // ensure that the input is in proper format
    if (Validators.validateSwipeNum(input)) {
      // convert swipe number to UID
      timeout(8000, Backend.convertSwipeNumToUID(input))
        .then((res_convert) => {
          setUID(res_convert.UID);
          // get user info from UID
          timeout(8000, Backend.getUser(res_convert.UID))
            .then((res_getuser) => {
              // Check if UID from swipenum is in db. If it is, proceed to machine selection page.
              // If it isn't, proceed to registration screen
              if (res_getuser.status === 200 && res_convert.status === 200) {
                onSubmit(res_getuser);
                Backend.isUserSwipedIn(res_convert.UID, currLab.id).then((swiped_in) => {
                  if (swiped_in) {
                    setShowChoicePopup(true); // prompt user to swipe out or reserve more machines with SwipeOrReserve component
                  } else {
                    Backend.userSwipeIn({
                      userUID: res_convert.UID,
                      userSwipeNum: input,
                      lab: currLab.id,
                    });
                    typeof routeFunc === "function" &&
                      history.push("/machine_selection"); // go to machine selection page
                  }
                });
              } else {
                onSubmit({ status: res_convert.status, UID: res_convert.UID });
                typeof routeFunc === "function" && history.push("/register"); // go to register page
              }
              setIsLoading(false);
            })
            .catch((e) => {
              flashErrorMessage(
                "We were unable to process your request. Please try again."
              );
            });
        })
        .catch((e) => {
          flashErrorMessage(
            "We were unable to process your request. Please try again."
          );
        });
    } else {
      flashErrorMessage(
        "Card could not be found in the UMD system. Please swipe a valid university ID card."
      );
    }
    setCurrLab(currLab);
  }

  // used to create time out messages
  function timeout(ms, promise) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        reject(
          new Error("We were unable to process your request. Please try again.")
        );
      }, ms);
      promise.then(resolve, reject);
    });
  }

  // Displays an auto-disappearing message to the user
  function flashErrorMessage(message) {
    setIsLoading(false);
    setInput("");
    setShowErrorMessage(true);
    setErrorMessageText(message);
    setTimeout(() => {
      setShowErrorMessage(false);
      setErrorMessageText("");
    }, 3000);
  }

  useEffect(() => {
    if (labs.length === 0) {
      Backend.getLabs(1000).then((res) => {
        setLabs((res && res.items) || []);
        if (!currLab) {
          setCurrLab(res.items.length > 0 && res.items[0]);
        }
      });
    }
  }, [currLab, labs, setCurrLab]); //bug when labs is not initialized in time

  return (
    <div
      onClick={() => {
        inputRef.current.focus();
      }}
    >
      <Stack>
        <Image
          alignSelf="start"
          width="3em"
          src={pinpointLogo}
          alt="pinpoint logo"
          margin="2vh"
          position={"absolute"}
        ></Image>
        <Flex
          minH={"100vh"}
          align={"center"}
          justify={"center"}
          bg={useColorModeValue("gray.50", "gray.800")}
        >
          <Stack spacing={12} mx={"auto"} maxW={"lg"} py={12} px={6}>
            <Stack align={"center"}>
              <Heading fontSize={"6xl"} fontWeight="bold">
                Welcome
              </Heading>
              <Text fontSize={"xl"} color={"gray.600"}>
                to {currLabName}
              </Text>
            </Stack>
            <Box
              rounded={"2xl"}
              bg={useColorModeValue("white", "gray.700")}
              boxShadow={"lg"}
              p={8}
              paddingTop={0}
              height={"55vh"}
            >
              <form onSubmit={processSwipe}>
                <Center height={"25vh"} marginTop="5vh" marginBottom="25vh">
                  {isLoading ? (
                    <Spinner alignSelf="center" />
                  ) : (
                    <Lottie animationData={swipeAnimation} loop={true} />
                  )}
                </Center>

                <FormControl id="UID">
                  <Input
                    ref={inputRef}
                    type="password"
                    autoFocus={true}
                    outline="none"
                    border="none"
                    textColor={"transparent"}
                    focusBorderColor="none"
                    value={input}
                    onChange={(e) => {
                      const numMatch = e.target.value.match(/\d*/);
                      numMatch && setInput(numMatch[0]);
                    }}
                  />
                </FormControl>
              </form>
            </Box>
            <Text
              fontSize={"md"}
              color={showErrorMessage ? "red" : "gray.600"}
              textAlign="center"
            >
              {showErrorMessage
                ? errorMessageText
                : "Please swipe your university ID card to check in."}
            </Text>
          </Stack>
        </Flex>
        {showChoicePopup && (
          <SwipeOrReservePopup
            isOpen={showChoicePopup}
            onClose={() => {
              setShowChoicePopup(false);
            }}
            onCheckOut={() => {
              Backend.userSwipeOut(UID, currLab.id);
            }}
          ></SwipeOrReservePopup>
        )}
      </Stack>
    </div>
  );
}

KioskLogin.propTypes = {
  onSubmit: PropTypes.func,
};

export default withRouter(KioskLogin);
