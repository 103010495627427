import { extendTheme } from "@chakra-ui/react";
import { createTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

const chakraTheme = extendTheme({
  colors: {
    brand: {
      white: "#FFFFFF",
      black: "#000000",
      red: "#ed1c24",
      orange: "#f73c03",
    },
  },
  fonts: {
    body: "Inter, sans-serif",
    heading: "Inter, sans-serif",
    mono: "Menlo, monospace",
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "bold",
        borderRadius: "md",
      },
      variants: {
        solid: {
          background: "brand.red",
          fontFamily: "heading",
          mt: 8,
          w: "full",
          bgGradient: "linear(to-r, #ed1c24,#f73c03)",
          color: "white",
          _hover: {
            bgGradient: "linear(to-r, #ed1c24,#f73c03)",
            boxShadow: "xl",
          },
          _disabled: {
            bgGradient: "linear(to-r, #ed1c24,#f73c03)",
            boxShadow: "xl",
          },
          _active: {
            bgGradient: "linear(to-r, #ed1c24,#f73c03)",
          },
          _focus: {
            bgGradient: "linear(to-r, #ed1c24,#f73c03)",
          },
        },
      },
    },
  },
});

const muiTheme = createTheme({
  palette: {
    primary: red,
  },
  typography: {
    fontSize: 20,
  },
});

export { chakraTheme, muiTheme };
