import React, { useState, useEffect } from 'react';
import {
   InputLabel,
   FormControl,
   MenuItem,
   Select,
   Button
}from '@material-ui/core';
import './EditMachineDropDown.css';
import TextField from '@material-ui/core/TextField';
import SubmitButton from '../NewMachineDropDown/SubmitButton';
import DeleteButton from './DeleteButton.jsx'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( ({
   dropDownMenu: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      overflow: 'hidden',
   
      listStyle: 'none',
      width: '100%',
   },
   
   centerDiv: {
      textAlign: 'center',
      marginTop: '1em',
   },
   
   button: {
      textAlign: 'center',
      width: ' fit-content',
   }
   }));


export default function SliderFormDropDownExtra(
   {title, 
   fields,
   dataFunctionMachineTypes,
   dataFunctionToolCategories,
   dataFunctionAuto,
   onSubmit,
   deleteForm = false}
   ) {
   const classes = useStyles();
   const [submission, setSubmission] = useState({"machineType": "None"})
   const [errors, setErrors] = useState({})
   const [choice, setChoice] = useState('')
   const [machineTypeData, setDataMachineTypes] = useState([]);
   const [machineName, setMachineName] = useState('');
   const [machineType, setMachineType] = useState('');
   const [categoryID, setMachineToolCategory] = useState({ categoryID: "None"});
   const [toolCategoryData, setToolCategoryData] = useState([]);
   const [originalData, setOriginalData] = useState({});
   
   useEffect(() =>{
      dataFunctionMachineTypes().then(({items, status}) => {
         if (status === 200){
            setDataMachineTypes(items);
         } else {
            alert("server call failed!");
         }
      });

      dataFunctionToolCategories().then(({ items , status }) =>{
         if (status === 200) {
           setToolCategoryData(items);
         } else {
           alert("Unable to create a new machine at this time, can't retrieve tool categories from server")
         }
      });
   }, [dataFunctionMachineTypes]);

   useEffect(() =>{
      dataFunctionAuto()
     .then(({name, machineType, categoryID, categoryName, status}) => {
         if (status === 200){
            console.log(name)
            setMachineName(name)
            setOriginalData ({
               machineType: machineType,
               categoryID: categoryID,
               categoryName: categoryName
            })

            setSubmission({
               ...submission,
               name: name
            })


         } else {
            alert("server call failed!");
         }
      });
   }, [dataFunctionAuto]);
   
   const handleChange = (e, key) => { 
      let newValue;
      if (key === 'machineType') {
         newValue = e.target.value.name;
         setMachineType(newValue);
      } else if (key === 'categoryID') {
         newValue = e.target.value.categoryID;
         setMachineToolCategory(newValue);
      }
      
      setSubmission(
         {...submission,
         [key]: newValue
         }
      )
   }

   const onChange = (e, key) => {
      setMachineName(e.target.value)
      setSubmission(
         {...submission,
         [key]: e.target.value
         }
      )
      var result = fields.find(obj => obj.key === key)

      if ((result.validate !== "") && !e.target.value.match(result.validate)) { 
         setErrors(
            {...errors,
            [key]: result.errorText
            }
         )
      }
      else { 
         setErrors(
            {...errors,
            [key]: ""
            }
         )
      }
   }


   let button;
   if (deleteForm) {
      button = <DeleteButton
         submission={submission}
         errors={errors}
         fields={fields}
         onSubmit={onSubmit}
      />;
   } else{
      button = <SubmitButton
         submission={submission}
         errors={errors}
         fields={fields}
         onSubmit={onSubmit}
      />
   }
   return (
      <form className="SliderFormDropDownExtra">
         {fields.map(f => 
            <TextField
               required
               margin="dense"
               key={f.key}
               id={f.key}
               label={f.placeholder}
               helperText={errors[f.key]}
               error={ errors[f.key] ? true : false }
               onChange={e => onChange(e, f.key)}
               value= {machineName}
               disabled = {f.disabled}
               defaultValue = {machineName}
            />,
         )}
         {/******  Drop Down Menu ******/}
         <FormControl
         required 
         className={classes.dropDownMenu}
         >
            <InputLabel htmlFor="machineType">{title}</InputLabel> 
            <Select
               inputProps={{
                  id: "machineType"
               }}
               onChange={(e => handleChange(e, "machineType"))}
            >
               <MenuItem value={machineTypeData.filter((item) => item['name'] === originalData.machineType)[0]}>
                  <em>{originalData.machineType}</em>
               </MenuItem>
               
               {machineTypeData.map(f => 
                  <MenuItem value={f}>{f['name']}</MenuItem>
               )}
            </Select>
         </FormControl>

         <FormControl
         required
         className={classes.dropDownMenu}
         >
         <InputLabel htmlFor="categoryID">{"Tool Category"}</InputLabel>
            <Select
               inputProps={{
                  id: "categoryID",
               }}
               value={console.log(categoryID)}
               onChange={(e) => handleChange(e, "categoryID")}
            >

               <MenuItem value={toolCategoryData.filter((item) => item['categoryID'] === originalData.categoryID)[0]}>
                  <em>{ originalData.categoryName }</em>
               </MenuItem>
               {toolCategoryData.map((f) => (
                  <MenuItem value={f}>{f["categoryName"]}</MenuItem>
               ))}
            </Select>
         </FormControl>
         <div className='centerDiv'>
            {button}
         </div>
      </form>
   );
}