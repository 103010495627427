import React, { useEffect, useState } from "react";
import Backend from "../utils/api.js";
import { withRouter } from "react-router-dom";
import pinpointLogo from "../assets/pinpoint_no_bg.svg";
import {
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  Image,
  Box,
} from "@chakra-ui/react";


function LoginCAS() {
  const [isLoading, setIsLoading] = useState(false);
  // const nextRedirect = 'https://stage.pinpoint.umd.edu/';
  const nextRedirect = 'http://localhost:3000/';

  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    
    // In the case that ticket is not null (i.e. we have a session), this is a callback from a CAS login
    const ticket = params.get('ticket');
    if (ticket) {
      setIsLoading(true);
      console.log(`ticket: ${ticket}`);
      
      Backend.casLoginCallback(ticket, nextRedirect).then((res) => {
        setIsLoading(false);
        console.log(`res:`);
        console.log(res);

        if (res.redirect) {
          window.location.replace(res.redirect);
        }
      });
    }
  }, []);

  const login = (e) => {
    e.preventDefault();
    setIsLoading(true);

    Backend.casLogin().then((res) => {
      setIsLoading(false)
      window.location.replace(res.redirect); // raw JS (ew)
    });
  }

  return(
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={20} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Image
          alignSelf="center"
          width="4em"
          src={pinpointLogo}
          alt="pinpoint logo"
          margin="2vh"
          position={"absolute"}
        ></Image>
        <Stack align={"center"}>
          <Heading fontSize={"5xl"} fontWeight="bold">
            Pinpoint
          </Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Text fontSize={"md"} color={"gray.600"} align={"center"}>
            Manage your profile & connect Pinpoint to your Canvas Badges account
          </Text>
          <form onSubmit={login}>
            <Button
              loadingText="Logging In..."
              isLoading={isLoading}
              type="submit"
            >
              Login with UMD CAS
            </Button>
          </form>

        </Box>
      </Stack>
    </Flex>
    )
}

export default withRouter(LoginCAS)
